
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Electronic",
  data() {
    return {
      tabBarList: [
        { code: 0, msg: "文书签收" },
        { code: 1, msg: "个人中心" },
      ],
    };
  },
  setup() {
    const router = useRouter();
    const activeIndex = ref(0);
    const tabBarFun = (item: any) => {
      activeIndex.value = item.code;
      if (item.code == 0) {
        router.push({
          path: "/documents-list",
        });
      } else {
        router.push({
          path: "/personal-center",
        });
      }
    };
    return {
      activeIndex,
      tabBarFun,
    };
  },
};
